<script setup lang="ts">
import { useVoixUserAuth } from '@voix/composables/useVoixUserAuth'
import { ref } from 'vue'

const props = defineProps<{
  title: string
  links?: Array<{
    label: string
    to?: string | { name: string }
    emit?: string
  }>
  permissions?: Array<string>
}>()

const isAllowed = ref(false)

if (!props.permissions)
  isAllowed.value = true

if (props.permissions) {
  const { checkUserPermissions } = useVoixUserAuth()
  isAllowed.value = checkUserPermissions(props.permissions)
}
</script>

<template>
  <div class="@container">
    <div v-if="isAllowed" class="px-12 py-8 bg-white w-full min-h-screen">
      <div class="border-b border-gray-300 pb-3 flex justify-between items-end">
        <h1 class="voix-admin-text font-semibold text-xl tracking-tight">
          {{ title }}
        </h1>
        <slot name="topbar" />
        <template v-for="(link, key) in props.links" :key="key">
          <NuxtLink
            v-if="link.to"
            :to="link.to"
            as="button"
            class="mt-4 py-2 px-4 rounded-full transition-colors duration-200 bg-gray-200 hover:voix-admin-bg text-gray-500 hover:text-white text-xs"
          >
            {{ link.label }}
          </NuxtLink>
          <button
            v-if="link.emit"
            class="mt-4 py-2 px-4 rounded-full transition-colors duration-200 bg-gray-200 hover:voix-admin-bg text-gray-500 hover:text-white text-xs"
            @click="$emit(link.emit)"
          >
            {{ link.label }}
          </button>
        </template>
      </div>
      <slot />
    </div>
    <div v-if="!isAllowed">
      <VoixAdminTheme>
        <div class="flex flex-col space-y-20 justify-center items-center h-screen">
          <div class="w-full flex flex-col items-center justify-center max-w-[900px]">
            <div class="w-full text-xl font-bold uppercase voix-admin-text-light border-b voix-admin-border-light pb-1">
              You are not allowed to access this page
            </div>
            <div class="mt-3 w-full flex justify-end space-x-4">
              <NuxtLink
                to="/voix/login"
                class="p-3 px-6 rounded-sm font-medium voix-admin-text-lightest voix-admin-bg-dark text-sm"
              >
                Login
              </NuxtLink>
              <NuxtLink
                to="/"
                class="p-3 px-6 rounded-sm font-medium voix-admin-text-lightest voix-admin-bg-dark text-sm"
              >
                Go to home
              </NuxtLink>
            </div>
          </div>
        </div>
      </VoixAdminTheme>
    </div>
  </div>
</template>
